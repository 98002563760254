import * as React from 'react'
import {container, description, info, introduction, name, nav} from '../styles/landing.module.scss'
import {graphql, useStaticQuery} from "gatsby";
import NavCard from "./nav-card";


const Landing = () => {

    const data = useStaticQuery(query);
    return (<div className={container}>
            <div className={info}>
                <div>
                    <h1 className={name}>امیر افسری</h1>
                    <h2 className={introduction}>مهندس کامپیوتر | کارافرین | عاشق فیزیک</h2>
                    <text className={description}>
                        <br/>
                        اینجا از چیز‌هایی که دوست دارم می‌نویسم.
                    </text>
                </div>
            </div>
            <div className={nav}>
                {data.topics.nodes.map(n => <NavCard title={n.title} description={n.description}
                                                     imageUrl={n.image.src.childImageSharp.fluid} link={n.slug}/>)}
            </div>
        </div>
    )
};

export const query = graphql`
     {
        topics: allTopicsJson {
            nodes{
                title
                description
                slug
                image {
                  src {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
            }
        }
    }
`;

export default Landing
