import * as React from "react"
import Layout from "../components/layout";
import Landing from "../components/landing";
import Helmet from 'react-helmet'
import '../styles/index.scss'

// markup
const IndexPage = () => {
    return (
        <Layout pageTitle="Home">
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="description"
                      content="مهندس امیر افسری | سایت شخصی امیر افسری; مهندس کامپیوتر از دانشگاه شریف، فعال در آموزش برنامه نویسی و کارافرینی"/>
                <title>امیر‌افسری</title>
                <meta name="keywords"
                      content="امیر افسری, امیر, افسری, مهندس, کامپیوتر, داشنگاه شریف, برنامه نویس, پروژه, کارافرینی, شرکت, استارتاپ, کد"/>

                <link rel="canonical" href="https://amirafsari.com/"/>
            </Helmet>
            <Landing/>
        </Layout>
    )
};

export default IndexPage
