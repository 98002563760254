import * as React from 'react'
import {container, desc, imageContainer, img, textContainer, titleText} from '../styles/nav-card.module.scss'
import {Link} from 'gatsby'
import Img from 'gatsby-image';

const NavCard = ({title, description, imageUrl, link}) => {
    return (

        <Link className={container} to={`/topics/${link}`} unselectable="on">
            <div className={imageContainer}>
                <Img fluid={imageUrl} alt={title}
                     className={img}
                /></div>
            <div className={textContainer}>
                <h4 className={titleText}>{title}</h4>
                <text className={desc}>{description}</text>
            </div>
        </Link>

    )
};


export default NavCard
